import {
	AppBar,
	Link,
	Toolbar,
	Button,
	Box,
	List,
	Container,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemText,
	Drawer,
	ListItemIcon,
	Divider,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import NextLink from "next/link";
import { fontWeight } from "@mui/system";
import {
	ArrowRight,
	ArrowRightAlt,
	Cancel,
	CancelOutlined,
	LibraryBooksOutlined,
	Menu,
	SwipeRight,
} from "@mui/icons-material";
import { useState } from "react";
import { green } from "@mui/material/colors";

export default function Header(props) {
	const linkStyle = {
		color: "grey.200",
		"&:hover": { color: "white", textDecoration: "none" },
		marginRight: 4,
	};

	const [drawerOpen, setDrawerOpen] = useState(false);
	const handleDrawerToggle = () => setDrawerOpen((prev) => !prev);

	const drawer = (
		<Box onClick={handleDrawerToggle}>
			<List>
				<ListItem>
					<ListItemButton>
						<ListItemIcon>
							<ArrowRightAlt />
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemButton href="/listings" component={NextLink}>
						<ListItemText primary="Listings" />
					</ListItemButton>
				</ListItem>
				<ListItem>
					<ListItemButton href="/applications" component={NextLink}>
						<ListItemText primary="Applications" />
					</ListItemButton>
				</ListItem>
				<ListItem>
					<ListItemButton href="/settings" component={NextLink}>
						<ListItemText primary="Settings" />
					</ListItemButton>
				</ListItem>
				<ListItem>
					<ListItemButton href="/help" component={NextLink}>
						<ListItemText primary="Help" />
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	return (
		<>
			<AppBar position="fixed" sx={{ bgcolor: "primary.dark" }}>
				<Container maxWidth="lg" sx={{ margin: "auto" }} disableGutters>
					<Toolbar>
						<LibraryBooksOutlined sx={{ mr: 1 }} />
						<Typography variant="h6" sx={{ flexGrow: 1 }}>
							LatList
						</Typography>
						<IconButton color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
							<Menu />
						</IconButton>
					</Toolbar>
				</Container>
			</AppBar>
			<Drawer
				open={drawerOpen}
				onClose={handleDrawerToggle}
				variant="temporary"
				anchor="right"
				PaperProps={{ sx: { bgcolor: "grey.300" } }}
			>
				{drawer}
			</Drawer>
		</>
	);

	return (
		<AppBar position="fixed">
			<Toolbar spacing={2}>
				<Typography variant="button" sx={{ flexGrow: 1 }}>
					LatList
				</Typography>
				<Button href="/listings" component={NextLink} sx={linkStyle}>
					Listings
				</Button>
				<Button href="/applications" component={NextLink} sx={linkStyle}>
					applications
				</Button>
				<Button href="/settings" component={NextLink} sx={linkStyle}>
					Settings
				</Button>
				<Button href="/help" component={NextLink} sx={linkStyle}>
					Help
				</Button>
			</Toolbar>
		</AppBar>
	);
}
