import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Spinner(props) {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={props.open}
			//onClick={handleClose}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}
