import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Fab from "@mui/material/Fab";
import Container from "@mui/material/Container";
import Header from "./header";
import Footer from "./footer";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useSession } from "next-auth/react";
import Spinner from "components/spinner";

function ScrollTop(props) {
	const { children, window } = props;
	//
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector("#back-to-top-anchor");

		if (anchor) {
			anchor.scrollIntoView({
				block: "center",
			});
		}
	};

	return (
		<Fade in={scrollTrigger}>
			<Box onClick={handleClick} role="presentation" sx={{ position: "fixed", bottom: 16, right: 16 }}>
				{children}
			</Box>
		</Fade>
	);
}

export default function Layout(props) {
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100,
	});

	//console.log(props.children.type.auth);
	const sessionStatus = useSession({ required: props?.children?.type?.noauth !== false ?? false });
	//console.log(sessionStatus);
	if (sessionStatus === "loading") {
		return <Spinner open={true} />;
	} else
		return (
			<>
				<Header />

				<Box sx={{ background: "#ddd", margin: 0, width: "100%" }}>
					<Container sx={{ paddingY: 15, height: "100%" }}>
						<div id="back-to-top-anchor" />
						{props.children}
						<ScrollTop {...props}>
							<Fab>
								<KeyboardArrowUp />
							</Fab>
						</ScrollTop>
					</Container>
				</Box>
			</>
		);
}
