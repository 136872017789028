//import "@/styles/globals.css";
//import { useSession } from "next-auth/react";
import Layout from "components/layout/layout";
import { SessionProvider } from "next-auth/react";
import globals from "./../styles/globals.css";

export default function App({ Component, pageProps: { session, ...pageProps } }) {
	return (
		<SessionProvider session={session}>
			{Component.noheader ? (
				<Component {...pageProps} />
			) : (
				<Layout>
					<Component {...pageProps} />
				</Layout>
			)}
		</SessionProvider>
	);
}
